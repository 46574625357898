import type { http } from "@/api";
import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient();

export async function handleQueryResponse<T>(
  response: ReturnType<typeof http<T>>,
) {
  const { ok, data, errors } = await response;
  if (!ok) {
    throw errors;
  }
  return data;
}
