import "../css/app.css";

import { queryClient } from "@/queries/query-client";
import { Link, createInertiaApp } from "@inertiajs/react";
import { AppProvider, Frame } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import * as Sentry from "@sentry/react";
import translations from "@shopify/polaris/locales/en.json";
import { QueryClientProvider } from "@tanstack/react-query";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import type { PropsWithChildren } from "react";
import { createRoot } from "react-dom/client";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [],
  });
}

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

function Providers({ children }: PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider
        i18n={translations}
        linkComponent={(props) => {
          if (props.external) {
            return <a {...(props as any)} target="_blank" />;
          }
          return <Link {...(props as any)} href={props.url} />;
        }}
      >
        <Frame>{children}</Frame>
      </AppProvider>
    </QueryClientProvider>
  );
}

createInertiaApp({
  title: (title) => `${title ? `${title} - ` : ""}${appName}`,
  resolve: (name) =>
    resolvePageComponent(
      `./pages/${name}.tsx`,
      import.meta.glob("./pages/**/*.tsx"),
    ),
  setup({ el, App, props }) {
    const root = createRoot(el);

    root.render(
      <Providers>
        <App {...props} />
      </Providers>,
    );
  },
  progress: {
    color: "#4B5563",
  },
});
